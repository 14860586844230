@import "variables";
@import "mixins";

.footer {
    // left: 196px;
    left: 0;
    width: 100vw;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 40px;
    background: $whiteColor;
    z-index: 9;
    box-shadow: $footerShadow;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer img{
    width: 100px;
    height: 20px;
    padding-left: 10px;
}