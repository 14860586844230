@import './mixins';
@import './variables';

.mi-jm-cards-div {
    padding: 10px;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    .mi-jm-card-box {
        width: 25%;
        min-height: 200px;
        max-height: 200px;
        padding: 10px;
        .mi-jm-card{
            height: 100%;
            width: 100%;
            box-shadow: $card_box_shadow;
        }
        
    }
}

// meeting details page
.mi-jm-details-page {
    height: calc(100vh - 200px);
    padding: 10px;
    .jm-user-details-div {
        display: flex;
        align-items: center;
        .jm-user-detail {
            min-width: 50%;
            .name-text {
                font-size: 0.75rem;
                color: $primaryColor;
                font-weight: 600;
            }
            .phone-text {
                font-size: 0.75rem;
                color: $secondaryColor;
            }
            .jm-header {
                font-size: 0.95rem;
                color: $primaryColor;
                font-weight: 700;
                padding-bottom: 0.5rem;
            }
            .jm-members {
                position: relative;
                display: flex;
                .img-div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    flex-direction: column;
                    img {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            box-shadow: $card_box_shadow;
                            cursor: pointer;
                        }
                }
                
                .member-details{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 250px;
                    background-color: $whiteColor;
                    width: 250px;
                    border-radius: 10px;
                    box-shadow: $card_box_shadow;
                    z-index: 9999;
                    .jm-member-icon-div {
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 20px 5px 10px;
                        img {
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                box-shadow: $card_box_shadow;
                            }
                            .close-modal {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                right: 10px;
                                top: 10px;
                                width: 20px;
                                height: 20px;
                                border: 2px dotted $primaryColor;
                                border-radius: 50%;
                                font-size: 14px;
                                font-weight: 800;
                                padding: 5px;
                                cursor: pointer;
                            }

                    }
                    .other-detail {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
    .jm-location-div {
        
        .jm-header {
                font-size: 0.95rem;
                color: $primaryColor;
                font-weight: 700;
                padding-bottom: 0.5rem;
            }
        .jm-location{
        display: flex;
        align-items: center;
        .location-card{
            padding-right: 15px;
        }
        // justify-content: space-between;
        .location-header {
            font-size: 0.85rem;
            color: $secondaryColor;
            font-weight: 700;
        }
        .icon-section{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-width: 30%;
            padding-right: 15px;
            .right-arrow {
                    font-size: 2rem !important;
                    color: $primaryColor;
                    font-weight: 900;
                }
        }
        
    }
    }
}