@import "./mixins";
@import "./variables";

.credits-table {
    @include tableProperty;
}

.credits-table {
    .sticky-header {
        position: sticky;
        left: 0;
        z-index: 2;
    }
    .sticky-td {
        position: sticky;
        left: 0;
    }
}

.remaining-credits {
    color: $primaryColor !important;
}
.avail-credit {
    min-width: 200px !important;
}

.credits-table tr:not(:nth-child(-n + 2)){
    .modal-box {
        position: absolute;
        left: 460px;
        bottom: 0px;
        background-color: $whiteColor;
        color: $primaryColor;
        box-shadow: 0px 0px 6px -2.9px $primaryColor;
        padding: 15px 10px;
        z-index: 101010 !important;
        max-width: 600px;
        height: auto;
        overflow: visible;
    }
}

.credits-table tr:nth-child(-n + 2){
    .modal-box {
        position: absolute;
        top: 1px;
        left: 460px;
        background-color: $whiteColor;
        color: $primaryColor;
        box-shadow: 0px 0px 6px -2.9px $primaryColor;
        padding: 15px 10px 10px;
        z-index: 101010 !important;
        max-width: 600px;
        height: auto;
        overflow: visible;
    }
}

.cancel-btn {
    position: absolute;
    top: -15px;
    width: 15px;
    height: 15px;
    right: 0;
    cursor: pointer;
    .x-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
}

.google-hit-table th:first-child, .google-hit-table td:first-child{
    position: sticky;
    left: 0;
    background-color: white; /* To hide the sticky columns' background color */
  }

.google-hit-table th:nth-child(2), .google-hit-table td:nth-child(2)
{
    position: sticky;
    left: 80px;
    background-color: white; /* To hide the sticky columns' background color */
  }

.google-hit-table th:nth-child(3), .google-hit-table td:nth-child(3) {
    position: sticky;
    left: 230px;
    z-index: 1;
    background-color: white; /* To hide the sticky columns' background color */
  }

  .google-hit-table th:first-child, .google-hit-table th:nth-child(2), 
.google-hit-table th:nth-child(3) {
    z-index: 2;
}
.google-hit-table td:first-child, .google-hit-table td:nth-child(2), 
.google-hit-table td:nth-child(3) {
    z-index: 1;
}

  .grand-total {
    position: relative !important;
  }

