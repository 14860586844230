@import "variables";
@import "mixins";

.claims-table {
    @include tableProperty;
}

.claims-scrollbar {
    height: calc(100vh - 250px);
    margin: 10px;
}

.claims-summary-scrollbar {
    height: calc(100vh - 210px);
    margin: 10px;
}

.claims-manager-thead {
    @include tableHeadProperty;
}

.local-claim-nav-tab {
    background-color: $table_titleTop_backgroundColor;
    box-shadow: $topBarShadow !important;
    border-bottom: $topBarShadow !important;
    padding: 5px !important;

    .nav-link {
        font-weight: 700;
        color: $blackColor;

        &.active {
            color: $primaryColor;
        }
    }
}

.jus-cnt-btw {
    justify-content: space-between !important;
}


#select-user-claim {
    position: relative;
    margin-left: 260px;
}

.page-title.claim-approval {
    flex: auto;
}

.pendingapproval-scrollbar {
    height: calc(100vh - 320px);
    margin: 8px;
}

.approvedclaim-scroll {
    height: calc(100vh - 320px);
    margin: 8px;
}

.rejected-claim-scroll {
    height: calc(100vh - 320px);
    margin: 8px;
}

.pendingapproval-records {
    // background-color: $whiteColor;
    border-bottom: 1px solid $primaryPaleLight;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.claim-user-details-img {
    display: flex;
    align-items: center;
}

.pendingapproval-user-imgage {
    width: 35px;
    margin-left: 10px;
    flex-direction: column;
}

.pendingapproval-username-details {
    display: grid;
    margin-left: 10px;
    font-size: $fz14;
}

.claim-select-all {
    padding: 10px;
}

.approved-claim-thead {
    background-color: $primaryVeryLight;
    padding: 5px auto !important;
    color: $approvedClaimColor;
    font-size: $fz13;
    font-weight: $fwt500;
    width: max-content;
    // border-bottom: 2px solid rgba(92, 92, 92, 0.1490196078);
    vertical-align: middle !important;
    position: relative;
    top: 0;

    tr {
        padding: 5px 5px !important;
    }

    td {
        padding: 5px !important;
    }
}

.meeter-reading {
    font-weight: $fwt600;
    margin-bottom: 10px;
}


.btn-download-userwise-report {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    margin-left: 5px;
    flex: none;
    align-items: center;

    &:hover {
        background-color: $primaryColor;
        color: $whiteColor;
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

.pendingapproval {
    width: max-content;
    margin-bottom: 0px;
}

.pendingapproval td {
    max-width: max-content;
}

.pendingapproval-thead {
    @include tableHeadProperty;
    background-color: $primaryVeryLight !important;
    position: relative;
}

.pendingapproval-row-data {
    .input-approve-amount {
        width: 80px;
        border-radius: 0px;
        border: 1px solid gray;
        padding: 2px 5px;
        font-size: $fz12;

        :focus {
            box-shadow: none;
            border-radius: 0px;
            border: 1px solid gray;
        }
    }

    .remarks-td {
        // width: 500px;
        border-radius: 0px;
        border: 1px solid gray;
        padding: 3px 5px;
        font-size: $fz12;
    }

    &.total-value {
        border-top: 1.5px solid rgb(94, 94, 94);
        border-bottom: 1.5px solid rgb(94, 94, 94);

        td {
            padding: 6px 5px !important;
        }
    }
}

.claim-value-0 {
    cursor: auto;
    text-decoration: none;
}

.download-and-pagination .btn-approve-claim {
    @include buttonProperty($primaryColor, auto, auto, 0, 14px, $whiteColor);
    padding: 5px 10px;
    margin-left: 10px;
}

.btn-cancel {
    @include buttonProperty($grayColor, 30, auto, 0, 14px, $whiteColor);
    // padding: 5px 15px;
    margin-right: 10px;
}


.approved-claim-manager,
.pending-claim-admin {
    font-size: $fz12;
    color: $OrangeColor;
    font-weight: $fwt500;
}

.claim-admin-user-phoneno {
    font-size: $fz10;
    color: $OrangeColor;
}

a {
    text-decoration: none;
}



/* SCROLL BAR CSS with Styling START */
/* width */
.claims-scrollbar,
.pendingapproval-scrollbar,
.approvedclaim-scroll,
.rejected-claim-scroll {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
}

/* Track */
.claims-scrollbar,
.pendingapproval-scrollbar,
.approvedclaim-scroll,
.rejected-claim-scroll {
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.claims-scrollbar,
.pendingapproval-scrollbar,
.approvedclaim-scroll,
.rejected-claim-scroll {
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.claims-scrollbar,
.pendingapproval-scrollbar,
.approvedclaim-scroll,
.rejected-claim-scroll {
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

/* SCROLL BAR CSS with Styling END */

.claims-table.usrhrchy {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// .page-content.usrhrchy{height:calc(100vh - 150px)}
#uncontrolled-tab-example {
    width: 100%;
}

.local-claim-nav-tab.usrhrchy.nav.nav-tabs {
    box-shadow: none !important;
}

.usrhrchybtn {
    float: right;
}

.popup-bottom-btn.usrhrchy {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}

// .amc-model.hrschy .modal-content{height:500px;}
.local-claim-nav-tab.usrhrchy {
    display: flex;
    align-items: center;
}

.search-row.usrhrchy {
    width: 100%;
}

.slctusr {
    margin: 0 auto;
}

.filter-box-div.usrhrchy {
    float: right;
}

// .amc-model.traveldesk-width {
//     .modal-content {
//         width: 50% !important;
//         margin-left: 30% !important;
//     }

//     .modal-dialog {
//         max-width: 45% !important;
//     }
// }

.amc-model.hrschy {
    .modal-content {
        width: 100% !important;

    }

    .modal-dialog {
        max-width: 45% !important;
    }
}


// .amc-model.attendance-movement {
//     .modal-content {
//         width: 55% !important;
//         margin-left: 30% !important;
//     }

//     .modal-dialog {
//         max-width: 48% !important;
//     }
// }

@media screen and (min-width:1100px) and (max-width:1600px) {

    .amc-model.attendance-movement,
    .amc-model.traveldesk-width,
    .amc-model.hrschy {
        .modal-dialog {
            max-width: 65% !important;
        }
    }

}

.usrhrchy-rdbtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mytbusrhrchy {
    width: 100%;
}

.mytbusrhrchy tr {
    border-bottom: 1px solid #ded6ff !important;
}

.mybtnclos {
    font-size: 20px;
    margin-left: 12px;
    cursor: pointer;
}

.my-tbhd tr th {
    padding: 5px 8px !important;
}

.add-inputs.usr-hrchy {
    border: 1px solid var(--border-color);
    padding: 10px;
}

.myadbtn {
    background: none;
    border: none;
    color: var(--primary-color);
    font-size: 24px;
}

.myhrchy-dta {
    display: flex;
    align-items: center;
}

.usrhrchy-lbl.float-hidden.float-select {
    display: block !important;
}

.usrhrchy-lbl.float-hidden.float-select .react-select-container-list .react-select-list__control {
    background: #fff !important;
}



.usrhrchy-tbcnt {
    display: block;
    margin-left: 45px;
}

.ctyclifi.dropdowns {
    display: flex;
}

.myoptns-listnm {
    width: 40%;
}

.myoptns-list {
    width: 60%;
}

.movetobtn {
    background-color: var(--primary-color);
    padding: 6px 12px;
    min-width: auto;
    border-radius: 25px;
    font-size: 13px;
    color: #fff;
    margin: 0px 5px;
    box-shadow: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.movetobtn:hover {
    font-size: 13px;
    color: #fff;
    background-color: var(--primary-color);
    padding: 6px 12px;
}

.ctyclass .cat-col.ctybtn .btn.movetobtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ctyclass .cat-col.ctybtn {
    height: 65vh;
}

.cty-cnt.clsa {
    margin-top: 0px !important;
}

.cty-cnt {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.usrhrchy.table-responsive.spares-scrollbar.clsa {
    margin-top: 0px !important;
}

.cat-col.mvt-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clsc-nme th {
    width: 55%;
}

.cat-col.mvt-btn .fa.fa-arrow-right {
    margin-left: 10px;
}

// .cat-col .fa.fa-arrow-left{margin-right: 10px;}
.spare-table-row-data .btn.movetobtn {
    background: none;
    color: var(--primary-color);
}

.material-symbols-outlined.rght {
    color: #fff !important;
    font-size: 24px !important;
}

.clasa .btn.movetobtn {
    padding: 0px !important;
}

.clasa .material-symbols-outlined {
    font-size: 24px !important;
    margin-left: 15px;
}

.ctysrchdrp {
    display: flex;
    align-items: center;
}

.ctysrchdrp .input-div .react-select-container-list {
    min-width: 300px !important;
}


/*25.06.24*/
.motslst {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.levllst {
    display: flex;
    align-items: center;
}

.mots-lst {
    margin-right: 10px;
    margin-bottom: 0px;
}

.popup-bottom-btn.usrhrchy.levlmot {
    border: none;
}

.lnchreimbrse {
    text-align: left;
}

.lunchamnt-cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.motslst.lunchrmbrse {
    margin-top: 2% !important;
}

.add-edit-model .modal-content {
    width: 100% !important;
    margin: auto;
}

.main_model.add-edit-model.amc-model.expensty.expnse {
    max-width: 100% !important;

}

.main_model.add-edit-model.amc-model.expensty.expnse .modal-content {
    width: 80% !important;

}

.form-control.form.add-input.myprsns {
    padding: 0px !important;
    border: none !important;

}

.form-control.form.add-input.myprsns .css-13cymwt-control:active {
    outline: 0 !important;
    border: none !important;

}

.myprsns .css-13cymwt-control {
    padding: 0px !important;
    border: none !important;

}

.form-control.form.add-input.strt {
    height: 35px !important;
}

.css-13cymwt-control {
    border: none !important;
    height: 35px !important;
    background: none !important;

}

.mybldta {
    display: flex;
    align-items: center;
}

// .mybillattach {
//     margin-left: 15px;
// }

// .mybillattach-img {
//     margin-left: 5px;
// }

.form-control.form.add-input.kmgps {
    // background: none !important;
    background-color: #e9ecef !important;
    // box-shadow: none !important;
}

.form-control.form.add-input.tmedta {
    height: 35px !important;
}

.myinrbildta .mybldta {
    margin-left: 5px;
    margin-right: 5px;
    align-items: center;
}

.myinrbildta .mybldta h6 {
    font-size: 13px !important;
    white-space: nowrap;
    margin-right: 5px;
}

.myinrbildta {
    display: flex;
    align-items: center;
}

// .mylclrecqst {
//     position: absolute;
//     bottom: 0px;
// }

.mybldta-txt {
    width: 100%
}

.mybldta-inpttxt {
    width: 100%
}

.mybldta-txt2 {
    width: 80%
}

.mybldta-inpttxt2 {
    width: 35%
}

.mybldta-txt3 {
    margin-left: 5px;
    font-size: 13px !important;
    width: 45%
}

.mybldta-inpttxt3 {
    width: 40%
}

.add-inputs.usr-hrchy.lclmncnt h6 {
    font-size: 14px !important;
}

.amc-model .modal-dialog {
    max-width: 950px !important;
}

.form-control.form.add-input.myprsns.css-b62m3t-container:focus-visible {
    border: none !important;
    outline: 0 !important;
}

.form-control.form.add-input.myprsns.css-b62m3t-container:active {
    border: none !important;
    outline: 0 !important;
}

// .back-btn img {
//     width: 20px !important;
//     height: 20px !important;
// }

.page-content.usrhrchy.lcltrcnt {
    height: 100% !important;
}

.lclmndta {
    display: flex !important;
    align-items: center !important;
}

.lclmndta h6 {
    margin-right: 10px;
}

.lclmndta .react-select-list__control.css-13cymwt-control {
    min-width: 153px !important;
    margin-right: 15px !important;
}

.lclmndta .react-select-container-list {
    min-width: 160px !important;
}

.lclmndta.slctcty .react-select-list__control.css-13cymwt-control {
    min-width: 160px !important;
    margin-right: 0px !important;
}



// .lclmndta .react-select-list__control.css-13cymwt-control:nth-child(3) {
//     margin-right: 0px !important;
// }

.cat-col.dtpick {
    padding: 0px 10px !important;
}

.col-md-2.cat-col.dtcnt {
    // width: 50px !important;
    padding: 0px !important;
}

.col-md-6.cat-col.dtcnt {
    padding: 0px 5px 0px 0px !important;
}

.cat-col.dtcnt {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 0px !important;
}


.cat-col.dtcnt.mn-cnt .react-select-container-list .react-select-list__control {
    min-width: 157px !important;
}

.cat-col.dtcnt.mn-incnt .react-select-container-list .react-select-list__control {
    min-width: 148px !important;
    padding-left: 8px !important;
}

.cat-col.dtcnt.mn-cnt {
    padding-left: 0px !important;
}

// .lclmndta.mymncnt {
//     width: 50% !important;
// }

.lclmndta.mymncntdta {
    width: 110% !important;
}

.table.spares-table-data {
    width: 100%;
    overflow: auto;
}

.table.lcltable tr th {
    white-space: nowrap !important;
}

.mncntmain {
    display: flex;
}

.lunch-modal .modal-dialog {
    max-width: 600px !important;
}

.algn-tabcnt {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none !important;
}

.algn-tabcntint {
    padding-left: 50px !important;
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .cat-col.dtcnt.mn-cnt .react-select-container-list .react-select-list__control {
        min-width: 132px !important;
    }

    // .cat-col.dtcnt {
    //     gap: 6px !important;
    // }
    .myinptcnt {
        width: 24% !important;
        margin-left: 5px;
    }

    .label-fixed {
        height: 16px;
        position: absolute;
        top: -12px;
        left: 0px !important;
    }


    .cat-col.dtcnt.mn-incnt .react-select-container-list .react-select-list__control {
        min-width: 170px !important;
        padding-left: 8px !important;
    }

    .cat-col.dtcnt.mn-cnt .react-select-container-list {
        min-width: 155px !important;
    }

    .cat-col.dtcnt.mn-incnt .react-select-container-list {
        min-width: 175px !important;
    }

    .mybldta-txt3 {
        font-size: 13px !important;
        margin-left: 5px;
        margin-right: 10px !important;

    }

    .cat-col.mt-4.advccsh {
        width: 41.5% !important;
    }

    .cat-col.dtcnt.mn-incnt.col {
        padding-right: 0px !important;
    }


    .form-group-custom .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;

    }

    .form-group-custom2 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom5 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom4 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom3 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }
}

.form-group-custom .form-control.form.add-input.form-control {
    border: 1px solid #5842bc !important;
}

.form-group-custom2 .form-control.form.add-input.form-control {
    border: 1px solid #5842bc !important;
}

.form-group-custom3 .form-control.form.add-input.form-control {
    border: 1px solid #5842bc !important;
}

.form-group-custom4 .form-control.form.add-input.form-control {
    border: 1px solid #5842bc !important;
}

.form-group-custom5 .form-control.form.add-input.form-control {
    border: 1px solid #5842bc !important;
}

.form-group-custom {
    position: relative;
    width: 100%;
    margin-right: 10px;

}

.form-group-custom2 {
    position: relative;
    width: 100%;
    margin-right: 10px;

}

.form-group-custom3 {
    position: relative;
    width: 100%;
    margin-right: 10px;

}

.form-group-custom4 {
    position: relative;
    width: 100%;
    margin-right: 10px;

}

.form-group-custom5 {
    position: relative;
    width: 100%;
}

.label-fixed {
    height: 16px;
    position: absolute;
    top: -12px;
    left: 4px;
    background-color: #e9ecef !important;
    padding: 0 5px;
    font-size: 12px;
}

.form-control {
    margin-top: 2px;
    /* Adjust this value as needed to create space for the label */
}

@media screen and (min-width:1601px) {
    .mybldta-txt3 {
        width: 24%;
    }

    .mybldta-inpttxt3 {
        width: 34%;
        padding: 5px;
    }

    .form-group-custom .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom2 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom3 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom4 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }

    .form-group-custom5 .form-control.form.add-input.mybldta-inpttxt.kmgps {
        text-align: center;
    }
}


@media screen and (min-width:1200px) and (max-width:1300px) {
    .mybldta-txt3 {
        width: 28% !important;
        margin-right: 4px !important;
    }

    .cat-col.dtcnt.mn-cnt .react-select-container-list {
        min-width: 125px !important;
    }

    .mybldta-inpttxt3 {
        width: 18% !important;
        padding: 8px;
    }

    .myinptcnt {
        width: 18% !important;
        margin-left: 5px;
    }
}

@media screen and (min-width:1301px) and (max-width:1400px) {
    .mybldta-txt3 {
        width: 28% !important;
        margin-right: 4px !important;
    }

    .cat-col.dtcnt.mn-cnt .react-select-container-list {
        min-width: 145px !important;
    }

    .mybldta-inpttxt3 {
        width: 18% !important;
        padding: 8px;
    }

    .myinptcnt {
        width: 19% !important;
        margin-left: 5px;
    }
}

@media screen and (min-width:1401px) and (max-width:1500px) {
    .mybldta-txt3 {
        width: 28% !important;
        margin-right: 4px !important;
    }

    .cat-col.dtcnt.mn-cnt .react-select-container-list {
        min-width: 155px !important;
    }

    .mybldta-inpttxt3 {
        width: 18% !important;
        padding: 8px;
    }

    .myinptcnt {
        width: 21% !important;
        margin-left: 5px;
    }
}

.form-group-custom6 {
    display: flex;
    align-items: center;
}

.myinptcnt {
    text-align: right;
    width: 36%;
    margin-left: 5px;
}

.css-t3ipsp-control {
    border: none !important;
    box-shadow: none !important;
}