@import "variables";
@import "mixins";

.feedback-view-container {
    width: 100%;
    height: 100vh;
    padding: 0px;
    display: flex;

    .heading {
        padding: 10px 16px 10px 15px;
        margin: 0 !important;
        height: 60px;
        width: 100%;
        background-color: #eee;
        z-index: 1000;

        .heading-avatar {
            // padding: 0 !important;
            cursor: pointer;

            .heading-avatar-icon img {
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
        }

        .heading-name {
            padding: 0 !important;
            cursor: pointer;

            .heading-name-meta {
                font-weight: 700;
                font-size: 100%;
                padding: 5px;
                padding-bottom: 0;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #000;
                display: block;
            }

            .heading-online {
                display: none;
                padding: 0 5px;
                font-size: 12px;
                color: #93918f;
            }
        }
    }

    .searchBox {
        padding: 0 !important;
        margin: 0 !important;
        height: 60px;
        width: 100%;

        .searchBox-inner {
            height: 100%;
            width: 100%;
            padding: 10px !important;
            background-color: #fbfbfb;

            input:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }

    .sideBar {
        padding: 0 !important;
        margin: 0 !important;
        background-color: #fff;
        overflow-y: auto;
        border: 1px solid #f7f7f7;
        height: calc(100% - 120px);

        .sideBar-body {
            position: relative;
            padding: 10px !important;
            border-bottom: 1px solid #f7f7f7;
            height: 72px;
            margin: 0 !important;
            cursor: pointer;

            &:hover {
                background-color: #f2f2f2;
            }

            .sideBar-avatar {
                // text-align: center;
                // padding: 0 !important;

                .avatar-icon img {
                    border-radius: 50%;
                    height: 49px;
                    width: 49px;
                }
            }

            .sideBar-main {
                padding: 0 !important;

                .row {
                    padding: 0 !important;
                    margin: 0 !important;
                }

                .sideBar-name {
                    padding: 10px !important;

                    .name-meta {
                        font-size: 100%;
                        padding: 1% !important;
                        text-align: left;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #000;
                    }
                }

                .sideBar-time {
                    padding: 10px !important;

                    .time-meta {
                        text-align: right;
                        font-size: 12px;
                        padding: 1% !important;
                        color: rgba(0, 0, 0, .4);
                        vertical-align: baseline;
                    }
                }
            }
        }
    }

    .conversation {
        // background-color: #F7F7F7;
        background-image: url('https://static.whatsapp.net/rsrc.php/v3/yl/r/gi_DckOUM5a.png');
        height: 100vh;

        .message {
            padding: 0 !important;
            margin: 0 !important;
            background-size: cover;
            overflow-y: auto;
            border: 1px solid #f7f7f7;
            height: calc(100% - 120px);
        }

        .message-body {
            margin: 0;
            padding: 10px;
            // width: auto;
            height: auto;

            .message-main-receiver {
                max-width: 60%;
            }

            .message-main-sender {
                padding: 3px 20px !important;
                margin-left: 40% !important;
                max-width: 60%;
            }

            .message-text {
                margin: 0 !important;
                padding: 5px !important;
                word-wrap: break-word;
                font-weight: 200;
                font-size: 14px;
                padding-bottom: 0 !important;
            }

            .message-time {
                margin: 0 !important;
                margin-left: 50px !important;
                font-size: 12px;
                text-align: right;
                background-color: $lightGrayishCyan;
                color: $primaryColor;
            }

            .receiver {
                width: auto !important;
                padding: 4px 10px 7px !important;
                border-radius: 10px 10px 10px 0;
                background-color: $lightGrayishCyan;
                color: $secondaryColor;
                box-shadow: $card_box_shadow;
                font-size: 12px;
                word-wrap: break-word;
                display: inline-block;
            }

            .sender {
                float: right;
                // width: auto !important;
                background-color: $lightGrayishCyan;
                color: $primaryColor;
                border-radius: 10px 10px 0 10px;
                padding: 4px 10px 7px;
                font-size: 12px;
                display: inline-block;
                word-wrap: break-word;
                box-shadow: $card_box_shadow;
            }
        }

        .reply {
            height: 60px;
            width: 100%;
            background-color: #f5f1ee;
            padding: 10px 5px 10px 5px !important;
            margin: 0 !important;
            z-index: 1000;

            .reply-emojis {
                padding: 5px !important;

                i {
                    text-align: center;
                    padding: 5px 5px 5px 5px !important;
                    color: #93918f;
                    cursor: pointer;
                }
            }

            .reply-recording {
                padding: 5px !important;

                i {
                    text-align: center;
                    padding: 5px !important;
                    color: #93918f;
                    cursor: pointer;
                }
            }

            .reply-send {
                padding: 5px !important;

                i {
                    text-align: center;
                    padding: 5px !important;
                    color: #93918f;
                    cursor: pointer;
                }
            }

            .reply-main {
                padding: 2px 5px !important;

                textarea {
                    width: 100%;
                    resize: none;
                    overflow: hidden;
                    padding: 5px !important;
                    outline: none;
                    border: none;
                    text-indent: 5px;
                    box-shadow: none;
                    height: 100%;
                    font-size: 16px;

                    &:focus {
                        outline: none;
                        border: none;
                        text-indent: 5px;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}