@import "variables";
@import "mixins";

.approver-table-part {
    .approver-table {
        tbody {
            tr {
                td {
                    .approver {
                        text-transform: capitalize;
                        margin: 0.25rem;

                        &.approved {
                            @include acceptedStatus;
                        }

                        &.pending {
                            @include pendingStatus;
                        }

                        &.rejected {
                            @include rejectedStatus;
                        }
                    }

                    .attachment {
                        cursor: pointer;
                        font-size: 1.2rem !important;
                        color: $primaryColor;
                    }
                    .change-status{
                        cursor: pointer;
                        color: $primaryColor;
                        span{
                            i{
                                padding-right: 0.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}