@import "variables";
@import "mixins";

.service-request-container {
    padding: 20px;
    background-color: #f6f6f6;
    margin: 20px 20px 0px 20px;

    // width: 70%;
    &:hover {
        background-color: $whiteColor;
    }
}

.service-request-first-div img {
    height: 250px;
    width: 250px;
}

.service-request-second-div {
    h5 {
        padding: 20px;
        font-weight: 600;
    }

    ul {
        li {
            font-size: 15px;

            &::marker {
                color: $warningColor;
            }
        }
    }
}

.service-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    button {
        width: 80px;
        height: 30px;
        border: none;
        background-color: $warningColor;
        color: $whiteColor;
        border-radius: 10px;
        position: absolute;
        right: 250px;
    }
}


/* Arrow styles */
.arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;

    .arrow-up {
        position: relative;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #333;
        /* Change color as needed */
        margin: 0 auto;
    }

    .arrow-line {
        width: 30px;
        height: 300px;
        background-color: #333;
        position: relative;
    }

    .arrow-text {
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
        color: #fff;
        /* Change color as needed */
        position: absolute;
        rotate: 270deg;
        top: 25%;
        width: 200px;
    }
}


.service-request-buttons button {
    // padding: 4px 10px;
    // min-width: 80px;
    // border-radius: 25px;
    // font-size: 15px;
    // color: #fff;
    // margin: 0px 5px;
    // box-shadow: none;
    // font-weight: 700;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // font-weight: 500;
    min-width: 80px;
    border-radius: 25px;
    font-size: 14px;
    color: #fff;
    margin: 0px 5px;
    box-shadow: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 30px;
}

.button1 {
    background-color: $compStatusColor;
    border: none;

    &:hover {
        background-color: $compStatusColor;
    }

    &:disabled {
        background-color: $compStatusColor;
        cursor: not-allowed;
    }
}

.button2 {
    background-color: $cancStatusColor;
    border: none;

    &:hover {
        background-color: $cancStatusColor;
    }

    &:focus &:focus-within {
        outline: none;
    }
}

.button3 {
    background-color: $commonStatusColor;
    border: none;

    &:hover {
        background-color: $commonStatusColor;
    }

    &:focus &:focus-within {
        outline: none;
    }
}