@import "variables";


.mi-template-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.mi-veriable-samples {
    padding: 10px;
    background: #eee;
}
.page-header-text-div {
    i {
        cursor: pointer;
        color: $darkBlue;
    }
    .edit-template{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
.mi-template-header-option {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px 5px;
    .form-control{
        line-height: 24px !important;
    }
    
    .character-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 0;
        .var_btn {
            cursor: pointer;
            color: $darkBlue;
            font-weight: 700;
        }
    }
    .optional-color {
        color: $unMappedStatusColor;
    }
    .textareabody {
        background: #fff;
        box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
        padding: 6px 15px;
        color: $primaryText_Color;
        max-height: 150px;
        overflow: auto;
        .template-textarea{
            box-shadow: none !important;
            padding: 0 !important;
            height: max-content;
        }
    }
}

.template-row {
    display: flex;
    align-items: flex-start;
    padding: 5px 5px;
    box-shadow: $topBarShadow;
    background-color: #eee;
    padding: 10px 0 0;
    .template-form {
        background-color: #fff;
        position: relative;
        overflow: auto;
        .template-div {
            height: 66vh;
            overflow: auto;
        }
    }
    .template-preview {
        background-color: #fff;
        .preview-header-text-div{
            padding: 15px;
        }
        .preview-body-div {
            padding: 20px;
            background-image: url('../../public/images/BG/whatsapp_background.png');
            
        }
        .config-preview-body {
            padding: 15px;
        }
        .header_preview_image{
            width: 100%;
            max-height: 150px;
        }
        .preview-header {
            color: $primaryColor;
            font-weight: 800;
            font-size: 14px;
        }
        .footer-text {
            color: $textHeadColor;
            padding: 10px 0 0;
        }

        .button-preview {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0 0;
            
            p, i{
                color: $darkBlue;
                font-weight: 700;
            }
            .fa-paper-plane{
                padding-right: 15px;
            }
        }
        .email_attachment_preview {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 3px;
            min-width: 20%;
            max-width: max-content;
            padding: 5px;
            background-color: #e7e7e7;
            
            p, i{
                color: $darkBlue;
                font-weight: 700;
            }
            .fa-file-text-o{
                padding-right: 15px;
            }
        }
        .text-body {
                background-color: #fff;
                border-radius: 4px;
                max-width: 85%;
                color: $primaryColor;
                position: relative;
                padding: 15px;

                &::after {
                    position: absolute;
                    top: 4px;
                    content: "";
                    width: 0;
                    height: 0;
                    border-top: 12px solid #fff;
                    border-left: 12px solid transparent;
                    left: -12px;
                }
                
            }
            .emaitext-body {
                border-radius: 4px;
                max-width: 100%;
                color: $primaryColor;
                position: relative;
                padding: 8px;
            }
            
        .email-msg-text {
            padding: 10px;
            border-radius: 2px;
            min-height: 100px;
            background-color: #f9f9f9;
            box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .input-group-text {
            font-size: 13px !important;
            padding: 3px 15px !important;
            min-width: 62px;
        }
    }
}



.veriable-sample-input {
    display: flex; align-items: center; justify-content: flex-start;
    padding: 5px 0;
    .fa-trash {
        cursor: pointer;
    }
}
.header-option-div {
    display: flex; align-items: flex-start; justify-content: flex-start;
    padding: 5px 0;
    width: 100%;
    .submenu_modal {
        position: absolute;
        bottom: 35px;
        box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
        background-color: #fff;
        z-index: 2;
        padding: 4px;
        border-radius: 4px;
        max-height: 200px;
        overflow: auto;
    }
}
.header_image_div{
    padding-left: 10px;
    position: relative;
    .fa-times-circle {
        position: absolute;
        left: 12px;
        top: 4px;
        cursor: pointer;
    }
    .header_image {
        width: 100px;
        height: 100px;
        border-radius: 6px;
        border: 1px solid #eee;
        cursor: pointer;
        box-shadow: 0px 0px 6px -1.4px $primarySemiLight !important;
    }
}
