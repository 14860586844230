// *----- COLOR VARIABLES ------*//

// $mainBody_backgroundImg: url(../images/bg.png);

:root {
  --primary-color: #5842bc;
  --primary-semilight: #7e69db;
  --primary-palelight: #b2a5ee;
  --primary-verylight: #e8e4faba;

  --modal-background: rgba(241, 237, 255, 0.31);
  --box-gradient: linear-gradient(135deg, #aa41ec 0%, #5b3cd7 80%, #ff847f 100%);
  --text-gradient: linear-gradient(90deg, #4c32be 0%, #7e69db 50%, #ff847f 100%);
  --button-linear-gradient: linear-gradient(to right, rgba(85, 47, 144) 80%, rgba(197, 106, 133));

  --login-secondary-background: linear-gradient(90deg, #ff847f 0%, #7e69db 50%, #4c32be 100%);
  --tab-background: linear-gradient(90deg, #ff847f 0%, #ec9f9c 50%, #ecbbba 100%);

  --seconday-text-gradient: linear-gradient(90deg, #4c32be 0%, #7e69db 50%, #ff847f 100%);

  --theme-tooltip-background: linear-gradient(135deg, #74d9b4 0%, #04A96D 80%, #FFB900 100%);

  --secondary-color: #ff847f;
  --secondary-semilight: #ec9f9c;
  --secondary-palelight: #ecbbba;

  --pending-color: #ee9604;
  --pending-background: #fdf6e5;

  --accept-color: #01b81a;
  --accept-background: #dbfadf;

  --reject-color: #fb1100;
  --reject-background: #ffe2e0f2;

  --common-color: #7987a1;
  --common-background: #a6b4cd42;

  --follow-color: #ffb900;
  --follow-background: #ffeec091;

  --complete-color: #01b81a;
  --complete-background: #01b81a10;

  --unmapped-color: #5842bc;
  --unmapped-background: #0101b83c;

  --cancel-color: #f44336;
  --cancel-background: #ffe2e0f2;

  --expire-color: #FF5733;
  --expire-background: #ff573326;

  --text-primary: #3b4863;
  // --text-primary: #596882;
  --header-primary: #1c273c;


  --box-light-shadow: 0px 0px 6px -1.9px #a894ff;
  --box-hover-shadow: 0px 0px 8px -1.9px #a894ff;
  --box-backgrond-light: rgb(246 244 255);
  --box-card-gradient: linear-gradient(90deg, #4c32be 0%, #7e69db 35%, #b2a5ee 100%);
  --box-card-part-gradient: linear-gradient(90deg, #775bf5 0%, #8c7cd3 12%, #b2a5ee 30%, #fff 30%, #fff 100%);

  --select-input-shadow: 0px 0px 6px -1.4px #a894ff !important;
  --select-input-hover-shadow: 0px 0px 6px -0.1px #a894ff;

  --filter-shadow: 0px -3px 3px -2px rgb(168, 148, 255),
    0px 3px 3px -2px rgb(186, 176, 230);

  //--toottip-color: #04A96D;
  --toottip-background: #fedfdf;

  --table-head-color: #4e5c76;
  --table-body-color: #3b4863;
  --table-main-text: #1c273c;
  --table-sub-text: #7987a1;
  --table-row-hover: #dfd8ff;

  --border-color: #a894ff;
  --topbar-shadow: 0px 3px 3px -2px #a894ff;
  --footer-shadow: 0px 3px 3px 4px #a894ff;

  --siderbar-active-hover: linear-gradient(to right, rgba(183, 169, 248, 0.598) 30%, transparent 100%);
  --lightGrayishColor: #f7f5ff;
  --light-background-color: #e4ddff;

  --upload-btn-gradient: radial-gradient(#fff, #e4e4e4 70%);
  --upload-btnhover-gradient: radial-gradient(#fff, rgba(115, 102, 175, 0.301) 70%);
  --input-background: #dfd8ff;
}

[data-theme="dark"] {
  --primary-color: #04A96D;
  --primary-semilight: #58cca1;
  --primary-palelight: #92ecc9;
  --primary-verylight: #e5faf2d8;

  --modal-background: #d5f3e83d;
  --box-gradient: linear-gradient(135deg, #74d9b4 0%, #04A96D 80%, #FFB900 100%);
  --text-gradient: linear-gradient(90deg, #04A96D 0%, #74d9b4 50%, #FFB900 100%);
  --button-linear-gradient: linear-gradient(to right, #04A96D 80%, #FFB900);

  --login-secondary-background: linear-gradient(90deg, #FFB900 0%, #74d9b4 50%, #04A96D 100%);
  --tab-background: linear-gradient(90deg, #FFB900 0%, #ffb900a8 50%, #f3c855 100%);

  --theme-tooltip-background: linear-gradient(135deg, #aa41ec 0%, #5b3cd7 80%, #ff847f 100%);

  --secondary-color: #FFB900;
  --secondary-semilight: #f3c855;
  --secondary-palelight: #ffb900a8;

  --pending-color: #ee9604;
  --pending-background: #fdf6e5;

  --accept-color: #01b81a;
  --accept-background: #dbfadf;

  --reject-color: #fb1100;
  --reject-background: #ffe2e0f2;

  --follow-color: #ffb900;
  --follow-background: #ffeec091;

  --common-color: #7987a1;
  --common-background: #a6b4cd42;

  --complete-color: #01b81a;
  --complete-background: #01b81a10;

  --cancel-color: #f44336;
  --cancel-background: #ffe2e0f2;

  --expire-color: #FF5733;
  --expire-background: #ff573326;

  --text-primary: #596882;
  --header-primary: #1c273c;

  --box-light-shadow: 0px 0px 6px -1.9px #7ce5aa;
  --box-hover-shadow: 0px 0px 8px -1.9px #7ce5aa;
  --box-backgrond-light: rgba(236, 251, 251, 0.3607843137);
  --box-card-gradient: linear-gradient(90deg, rgba(4, 169, 109, 1) 0%, rgba(54, 196, 145, 1) 35%, rgba(136, 222, 191, 1) 100%);
  --box-card-part-gradient: linear-gradient(90deg, rgba(4, 169, 109, 1) 0%, rgba(54, 196, 145, 1) 12%, rgba(136, 222, 191, 1) 30%, #fff 30%, #fff 100%);

  --select-input-shadow: 0px 0px 6px -1.4px #7ce5aa !important;
  --select-input-hover-shadow: 0px 0px 6px -0.1px #7ce5aa;

  --filter-shadow: 0px -3px 3px -2px rgba(4, 169, 109, 0.318),
    0px 3px 3px -2px rgba(4, 169, 109, 0.318);

  // --toottip-color: #04A96D;
  --toottip-background: #fee7cd;

  --table-head-color: #4e5c76;
  --table-body-color: #3b4863;
  --table-main-text: #1c273c;
  --table-sub-text: #7987a1;
  --table-row-hover: #dbfce9;

  --border-color: #7ce5aa;
  --topbar-shadow: 0px 3px 3px -2px #7ce5aa;
  --footer-shadow: 0px 3px 3px 4px #7ce5aa;
  --siderbar-active-hover: linear-gradient(to right, rgba(66, 138, 121, 0.1) 30%, transparent 100%);

  --lightGrayishColor: #F8FEFE;
  --light-background-color: #ecfff8;

  --upload-btn-gradient: radial-gradient(#fff, #e4e4e4 70%);
  --upload-btnhover-gradient: radial-gradient(#fff, rgba(247, 206, 99, 0.5411764706) 70%);

  --input-background: #e9fcf5;
}

$primaryColor: var(--primary-color);
$primarySemiLight: var(--primary-semilight);
$primaryPaleLight: var(--primary-palelight);
$primaryVeryLight: var(--primary-verylight);

$primaryHeaderColor: var(--header-primary);

$secondaryColor: var(--secondary-color);
$secondarySemiLight: var(--secondary-semilight);
$secondaryPaleLight: var(--secondary-palelight);

$iconBox: var(--box-gradient);
$buttonLinerGradient : var(--button-linear-gradient);
$header_Gradient: var(--text-gradient);
$tab_Gradient: var(--tab-background);

$themeToolTip: var(--theme-tooltip-background);

$card_background: var(--box-card-gradient);
$card_part_background: var(--box-card-part-gradient);

$card_shadow_hover: var(--box-hover-shadow);
$card_box_shadow: var(--box-light-shadow);

$filterShadow: var(--filter-shadow);

// status colors
$pendStatusColor: var(--pending-color);
$pendStatusBackground: var(--pending-background);

$acceptedStatusColor: var(--accept-color);
$acceptedStatusBackground: var(--accept-background);

$rejectedStatusColor: var(--reject-color);
$rejectedStatusBackground: var(--reject-background);

$cancelledStatusColor: var(--cancel-color);
$cancelledStatusBackground: var(--cancel-background);

$followStatusColor: var(--follow-color);
$followStatusBackground: var(--follow-background);

$commonStatusColor: var(--common-color);
$commonStatusBackground: var(--common-background);

$compStatusColor: var(--complete-color);
$compStatusBackground: var(--complete-background);

$unMappedStatusColor: var(--unmapped-color);
$unmappedStatusBackgroun: var(--unmapped-background);

$cancStatusColor: var(--cancel-color);
$cancStatusBackground: var(--cancel-background);

$expirStatusColor: var(--expire-color);
$expirStatusBackground: var(--expire-background);

// Tooltip
$toolTipBackground: var(--toottip-background);

// defining table colors
$tableHead_Color: var(--table-head-color);
$tableTd_Color: var(--table-body-color);
$tableTd_MainText: var(--table-main-text);
$subText_Color: var(--table-sub-text);
$tableRowHover: var(--table-row-hover);
// defining table colors

$activUserBackground: var(--box-backgrond-light);
$borderColor: var(--border-color);

$topBarShadow: var(--topbar-shadow);
$footerShadow: var(--footer-shadow);
$sidebarActive: var(--siderbar-active-hover);

$selectInputShadow: var(--select-input-shadow);
$selectBoxShadow: var(--select-input-hover-shadow);

$primaryText_Color: var(--text-primary);

$lightGrayishCyan : var(--lightGrayishColor);

$lightBackgroundTheme: var(--light-background-color);

$uploadBtnColor: var(--upload-btn-gradient);
$uploadBtnHoverColor: var(--upload-btnhover-gradient);

$headerSecondary: #909297;
$dangerColor : #f80000;
$successColor : #15bb3e;
$mainBody_backgroundImg: #ebf3f1;
$color-gray: #9e9caf;
$paleGraphGreen: #b2a5ee;



$activeStatusBackground: #e5fce9;
$inactiveStatusBackground: #ffe9e9;

$loginBackground: var(--login-secondary-background);
$inputBox_backgroundColor: var(--input-background);

$mainBody_backgroundImg: #ebf3f1;
$color-gray: #9e9caf;
$paleGraphGreen: #b2a5ee;

$modalBackground: var(--modal-background);

$OrangeColor: #d76baf;
$OrangeRed: #ff524c;
$statusOrange: rgb(255, 101, 96);
$statusGreen: #4c32be;
$statusYellow: #e98783;
$lightYellow: #eec4c3;
$lightGreen: #ded6ff;
$lightBlue: #5136c73d;
$darkYellow: #fd726d;
$darkGreen: #4d31ca;
$darkBlue: #004c8aea;
$blackColor: #000000;
$textHeadColor: #7c7c7c;
$iconBackground: #f3f6fc;
$greenColor : #009525;

$inputFieldback_color: #fafafada;
$inputBackground: rgba(250, 250, 250, 0.8549019608);
$whiteColor: #fff;
$paleWhite: #f5f5f5;

$nav_back_Graycolor: #1b1e24;
$nav_link_Textcolor: #8b91a0;
$table_titleTop_backgroundColor: #ffffff;
$table_titleTop_borderBottom: 2px solid #5c5c5c26;
$tablebody_backgroundColorY: #fbfbfb;
$tableHead_backgroundColor: #323232;
$modelhfbackgroundColor: #EDFCF9;
$modelhfborderColor: #a894ff;


$tableRow_borderBottom: #9ce1d4;
$grayColor: #919191;
$darkgrayColor: #5f5f5f;
$formLabelColor :#2e2e2e;
$lightGray: #e4e4e4;
$navLinkColor: #001988;
$scrollerBackgroundColor: #a0a0a079;
$scrollerThumbColor: #6969694f;
$warningColor : #ffcc00;
$paleOrange: #f7ce638a;
$danderColor : #cc3300;



$card_BoxShadow: 2px 2px 5px 1.1px #a894ff;

$projectBoxShadow :0px 0px 6px 1px hwb(0 0% 100% / 0.2);
$chatBoxShadow :0px 0px 3px 2px rgba(132, 132, 132, 0.2);
$lightParrotGreen: rgb(214 240 214 / 61%);
$paleGreen : rgb(196 214 194 / 79%);
$paleBlue: rgb(203 224 226 / 79%);
$palelightGreen: #e9f7f2;
$paleYellow : #fdfaeb;
$lightRed: rgba(253, 234, 234, 0.795);
$paleblueGreen : #017470;
$paleRed : rgb(227, 85, 85);

$approvedClaimColor: #3e4e6d;
$approvedClaimBackground: #f4fffb;


$paleGray: #c2c2c2;

$grayBoxShadowColor: #c0bfbf;
$brandGradient: linear-gradient(90deg, #00FF00, #FFA500, #FF0000);

$veryDarkGray : #5C5C5C;
$DarkCyan : #4d31ca;
$veryLightGray : #EBEBEB;
$veryPaleRed : rgb(255, 253, 253);
$mediumGray : rgba(0, 0, 0, 0.5);
$veryLightMediumGray : rgba(0, 0, 0, 0.2);
$lightGrayBlack : rgba(0, 0, 0, 0.603);
$very-Dark-Gray : rgb(102, 102, 102);
$lightGrayishBlue : #E9ECEF;
$darkCyanLimeGreen : #a894ff;

$vividYellow : #ff847f;
$softCyan : #5FDBC0;
$LightGray : #CBCBCB;
$lightBlack : rgba(0, 0, 0, 0.65);
$mediumLightGreen : #03a56a36;
$grayishRed : rgb(179, 172, 172);
$lightGrayishCyanLimeGreen : #C9ECDF;
$lightGrayishRed: rgba(206, 198, 198, 0.267);
$darkGray : rgb(128 128 128);
$veryDarkGrayishBlue : #5D6064;
$grayishCyanLimeGreen : rgba(201, 203, 202, 0.318);



// *----- FONT SIZE & WEIGHT ------*//

$tableHead_textSize: 15px;
$tableData_headTextSize: 0.9rem;
$tableHead_fontWeight: 500;

$fwt300: 300;
$fwt400: 400;
$fwt500: 500;
$fwt600: 600;
$fwt700: 700;
$fwt800: 800;

$fz6 : 6px;
$fz8 : 8px;
$fz10 : 10px;
$fz11 : 11px;
$fz12 : 12px;
$fz13 : 13px;
$fz14 : 14px;
$fz15 : 15px;
$fz16 : 16px;
$fz18 : 18px;
$fz20 : 20px;
$fz22 : 22px;
$fz24 : 24px;
$fz25 : 25px;
$fz26 : 26px;
$fz28 : 28px;
$fz30 : 30px;
$fz32 : 32px;
$fz34 : 34px;
$fz35 : 35px;
$fz36 : 36px;