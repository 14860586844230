@import "variables";
@import "mixins";

.signup-subscription-title {
    // background: $header_Gradient;
    background: $primaryColor;
        -webkit-background-clip: text;
        color: transparent;
    // color: $primaryColor;
    font-weight: 600;
    font-size: 16px;
    padding: 5px;
}
.signup-sbscription-img {
    width: 45px;
    border-radius: 50%;
    background: $iconBox;
    padding: 12px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 90%;
    }
}
.signup-module-card{
        margin: 10px 0px;
        padding: 15px 5px;
        width: 100% !important;
        align-items: center;
        border-radius: 0.4rem;
        border: none !important;
        transition: transform 0.2s;
        background: $whiteColor;
        position: relative;
        // min-height: 250px;
        z-index: 1 !important;
        cursor: pointer;
}
.signup-submenu-name {
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}
.signup-subscription-module{
    padding: 50px 10px;
}

.menu-item-div {
    padding: 0.5% 4.7%;
}
.signup-subscription-menu-container-second-inner-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
}
.signup-subscription-menus{
    font-weight: 600;
}
// .signup-subscription-toggle-btn-div{
//     position: absolute;
//     left: 450px;
// }
.signup-subscription-main-module{
    width: 65%;
    box-shadow: $card_shadow_hover;
    padding: 10px;
    margin: auto auto 10px auto;
    position: relative;
}
.signup-subscription-main-module-toggle-btn-div{
    position: absolute;
    right: 4%;  
}
.signup-module-btn-div{
    display: flex;
    justify-content: center;
    padding: 0px 0px 50px 0px;
}
.signup-subscription-menu-container-sub-menu-div{
    box-shadow: $card_shadow_hover;
    padding: 2% 0;
}
.signup-subscription-menu-container-sub-menu-first-inner-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5% 4%;
}
.signup-sub-dropdown-icon {
    position: absolute;
    right: 2%;
    font-size: 20px;
}