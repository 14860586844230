@import "variables";
@import "mixins";

.setting-card {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;
    width: 100%;
    padding: 0px 15px;
    min-height: 100px;

    .setting-card-body {
        text-align: left;
    }
}

.sb-setting-card {
    margin: 10px 0px;
    padding: 5px 15px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0rem;
    border: none !important;
    transition: transform 0.2s;
    background: $whiteColor;
}

.sb-setting-card:hover {
    transform: scale(1.01);
    box-shadow: $card_shadow_hover;
    .setting-img-card {
        box-shadow: $selectInputShadow;
    }
}

.sb-setting-card {
    img {
        width: 30px;
        height: 30px;
    }
}

.setting-page-header {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: rgba(201, 203, 202, 0.318) 0px 1px 0px 0px;

}

.input-div {
    position: relative;

    .search-icon {
        position: absolute;
        right: 3%;
        top: 30%;
        cursor: pointer;
    }
}

