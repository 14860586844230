@import "variables";
@import "mixins";

.task-table {
    @include tableProperty;
}

.task-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}


.btn-add-task {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    flex: none;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}

.task-left-part,
.task-right-part {

    // padding-right: 10px;    
    .card {
        margin: 5px;
        padding: 10px 12px;
        box-shadow: $projectBoxShadow;
        border: none;

        &:hover {
            background-color: $tablebody_backgroundColorY;
            box-shadow: $projectBoxShadow;
        }

        .task-img-status {
            margin-right: 15px;

            .task-img {
                padding: 10px;
                width: 85px;
                height: 85px;
                border-radius: 10px;

                &.completed {
                    background-color: $paleBlue;
                }

                &.pending {
                    background-color: $lightRed;
                }
            }

            .task-status {
                &.completed {
                    margin-top: 5px;
                    background-color: $paleblueGreen;
                    color: $whiteColor;
                    text-align: center;
                    padding: 3px 5px;
                    border-radius: 5px;
                }

                &.pending {
                    margin-top: 5px;
                    background-color: $paleRed;
                    color: $whiteColor;
                    text-align: center;
                    padding: 3px 5px;
                    border-radius: 5px;
                }

            }

        }

        .task-details {
            .p {
                margin: 3px auto !important;
            }
        }

        .client-code {
            margin-top: 10px;

            a {
                text-decoration: underline;
                font-size: 13px;
            }
        }

        .task-person-deatils {
            margin-left: 20px;



            .task-person-status {
                position: relative;

                .status-icon {
                    position: absolute;
                    right: 0px;
                    width: 20px;
                    height: 20px;
                }

                .edit-icon {
                    position: absolute;
                    right: 30px;
                    width: 15px;
                    height: 15px;
                }

                .task-person-img {
                    background-color: $lightParrotGreen;
                    padding: 5px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50px;
                }



                .task-details {
                    margin-left: 15px;

                    .p {
                        margin: 3px auto !important;
                    }
                }
            }

            .tasks-download {
                position: absolute;
                bottom: 10px;
                right: 10px;
                display: flex;

                .task-phase {
                    background-color: $lightGreen;
                    padding: 3px 4px;
                    text-align: center;
                    color: $darkGreen;
                    margin-right: 8px;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    text-decoration: underline;

                    i {
                        padding-right: 5px;
                    }
                }

                .task-task {
                    background-color: $lightYellow;
                    padding: 3px 4px;
                    text-align: center;
                    color: $darkYellow;
                    margin-right: 8px;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    text-decoration: underline;

                    i {
                        padding-right: 5px;
                    }
                }

                .task-download {
                    background-color: $lightBlue;
                    padding: 3px 4px;
                    text-align: center;
                    color: $darkBlue;
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    text-decoration: underline;

                    i {
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}



/* ADD & EDIT MODEL START */
.add-task-input {
    border-radius: 5px;
    font-size: 13px;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.btn-upload {
    margin-top: 8px;
    max-width: 230px;
}

.task-model {
    .modal-dialog {
        max-width: 700px !important;
    }

    .modal-content {
        width: 80% !important;
        margin-left: 90px;
    }

    .img-upload-part {
        margin-top: 8px;

        .upload-img-box {
            border: 2px dotted $modelhfborderColor;
            position: relative;
            height: 132px;
            padding: 5px;
            display: flex;
            border-radius: 6px;
            align-items: center;
            justify-content: center;

            .uploaded-image {
                position: relative;

                .task-image {
                    width: 200px;
                    height: 85px;
                }

                .upload-btn-attachment {
                    text-align: center;

                    .btn-upload-img {
                        font-size: $fz13;
                        padding: 2px 3px;
                        color: $navLinkColor;
                    }
                }
            }
        }
    }

    .add-edit-task {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .btn-save-up {
            background-color: $primaryColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-task-heading {
    @include modelHeader;
}

.btn-add-task-img {
    width: 100%;
    background-color: $lightGray;
    color: $blackColor;
    text-align: center;

    &:hover {
        color: $whiteColor;
        background-color: $grayColor;
    }
}


/* SCROLL BAR CSS with Styling START */
/* width */
.task-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.task-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.task-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.task-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */