@import "variables";
@import "mixins";


.leavetype-table {
    @include tableProperty;
}

.leavetype-scroll {
    height: calc(100vh - 200px);
    margin: 10px;
}

.leavetype-thead {
    @include tableHeadProperty;
}

.btn-add-purpose {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}

.leavetype-row-data .leavetype-edit-link {
    color: $primaryColor;
    font-size: $fz14;
}


/* ADD & EDIT CLIENT MODEL START */
.add-leavetype-model .modal-content {
    width: 100% !important;
}

.add-edit-leavetype {
    padding: 25px 20px;
}

.add-leavetype-heading {
    @include modelHeader;
}

.add-leavetype-input {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-leavetype-model .add-edit-leavetype .btns-save-cancel {
    display: inline-flex;
    margin-bottom: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.add-edit-leavetype .btn-save-up {
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 5px;
    padding: 5px 15px;
    height: 30px;

    p {
        font-size: $fz15;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        color: $whiteColor;
    }
}

.open-leave-type-2nd-part{
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .row-line{
        color: $primaryColor;
    }
    i{
        color: $primaryColor;
    }
}


.more-settings-part{
    margin-top: 10px;
}

.enable-radio-yes-no {
    display: flex !important;
    position: relative;
    left: 0px;
    top:5px;
    vertical-align: middle;
    align-items: center;

    .enable-radio-status {
        &.yes {
            margin-left: 0px;
        }

        &.no {
            margin-left: 20px;
        }
    }
}

.pr-border{
    border-right: 2px solid $primarySemiLight;    
}

.add-edit-leavetype .btn-cancel {
    background-color: $grayColor;
    color: $whiteColor;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
    height: 30px;

    p {
        font-size: $fz15;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        color: $whiteColor;
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.leavetype-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.leavetype-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.leavetype-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.leavetype-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */