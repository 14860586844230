@import "variables";
@import "mixins";

.products-table {
    @include tableProperty;
}

.products-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}

.products-thead {
    @include tableHeadProperty;
}

.btn-add-product {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}

.row-img-product {
    display: flex;
    align-items: center;

    .product-img-box {
        position: relative;
        display: flex;
        // background-color: $inputBox_backgroundColor;
        background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
        border: 1px solid $lightBackgroundTheme;
        border-radius: 5px;
        padding: 3px;
        width: 50px !important;
        height: 50px !important;

        .row-product-images {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.product-table-row-data .product-edit-link {
    color: $primaryColor;
    font-size: $fz13;
    padding: 0px 5px !important;
}

.view-productdetails-model {
    .modal-content {
        width: 800px;
        position: relative;
        right: 120px;
    }
    .product-detail-left-part {
        .product-big-img {
            position: relative;
            width: 100%;
            height: 320px;
            margin-bottom: 20px;
            transition: transform .2s;
            padding: 15px;
            border-radius: 10px;
            background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
            &:hover {
                -ms-transform: scale(1.05);
                /* IE 9 */
                -webkit-transform: scale(1.05);
                /* Safari 3-8 */
                transform: scale(1.05);
            }

            .product-img {
                // background-color: $table_titleTop_backgroundColor;
                // background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
                // border-radius: 10px;
                // padding: 10px;
                width: 100%;
                height: 300px;
                object-fit: contain;
            }

        }

        .carousel-sliding-part {
            background-color: $lightBackgroundTheme;
            border-radius: 10px;
            padding: 8px 10px;
            .product-small-images-row {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;

                .product-small-image {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    transition: transform .2s;
                    margin: 0 auto;
                    border-radius: 10px;
                    padding: 5px;
                    border: 1px solid gray;
                    background-color: $whiteColor;
                    // &:hover {
                    //     -ms-transform: scale(1.1);
                    //     /* IE 9 */
                    //     -webkit-transform: scale(1.1);
                    //     /* Safari 3-8 */
                    //     transform: scale(1.1);
                    // }
 
                    .product-img {
                        // background-color: $table_titleTop_backgroundColor;
                        background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 70%);
                        width: 100%;
                        height: 100%;
                        // padding: 5px;
                        // object-fit: contain;
                    }
                }
            }
        }
                
    }
}

.main-product-image {
    position: relative;
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-items: center;
    padding: 10px;
    border-radius: 4px;
    min-height: 200px;
    max-height: 200px;
    object-fit: contain;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
    .product-images {
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-width: 100px;
    }
}
.product-image-box {
    position: relative;
    display: flex;
    margin: 10px;
    width: 100px !important;
    height: 100px !important;
    align-items: center;
    justify-items: center;
    padding: 5px;
    // background-color: $lightBackgroundTheme;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 70%);

    .product-images {
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-width: 100px;
    }
    .delete-image {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
    .delete-icon {
        font-size: 18px !important;
    }
}

.product-details-model {
    padding: 20px;

    .product-detail-left-part {
        .product-big-img {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            transition: transform .2s;
            
            &:hover {
                    -ms-transform: scale(1.05);/* IE 9 */                  
                    -webkit-transform: scale(1.05);/* Safari 3-8 */                   
                    transform: scale(1.05);
                }

            .product-img {
                // background-color: $table_titleTop_backgroundColor;
                background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 90%);
                border-radius: 10px;
                padding: 10px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .product-small-images-row {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;

            .product-small-image {
                position: relative;
                width: 80px;
                height: 80px;
                transition: transform .2s;
                margin: 0 auto;
                // &:hover {
                //         -ms-transform: scale(1.1);/* IE 9 */                      
                //         -webkit-transform: scale(1.1);/* Safari 3-8 */                        
                //         transform: scale(1.1);
                //     }

                .product-img {
                    // background-color: $table_titleTop_backgroundColor;
                    background-image: radial-gradient($whiteColor, $whiteColor, $lightBackgroundTheme 70%);
                    border-radius: 10px;
                    padding: 5px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    cursor: pointer;
                }
            }
        }
    }

    .product-detail-right-part {
        max-height: 82vh;
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
        .product-name {

            h5 {
                font-size: $fz18;
                font-weight: bold;
            }
        }

        .product-details-by-row {
            padding-bottom: 10px;
            border-bottom: 2px solid $lightBackgroundTheme;

            .product-row {
                margin-bottom: 10px;
                font-size: $fz13;
                width: 100%;
                display: flex;
                // justify-content: space-between;

                .pdh {
                    width: 165px !important;
                    max-width: 165px;
                    font-weight: $fwt600;
                    color: $primaryColor;
                }

                .pdd {
                    color: $darkgrayColor;
                    width: 200px !important;
                    max-width: 200px;
                }
            }
        }

        .product-description {
            margin: 10px 0;

            .description-instruction {
                .description-instruction-nav-tab {
                    .nav-link {
                        color: $darkgrayColor;

                        &.active {
                            color: $primaryColor;
                        }
                    }
                }

                .tab-content {
                    margin-top: 10px;

                    p {
                        word-break: break-word;
                        text-align: justify;
                    }
                }
            }
        }
    }
    .product-big-img {
        .swiper-slide {
            height: 350px;
            padding: 20px;
            width: 100% !important;
        }
    }
    .product-detail-right-part {
        padding: 20px 10px;
    }

}

/* ADD & EDIT CLIENT MODEL START */

.add-product-input {
    border-radius: 5px;
    font-size: 13px;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-product-model {
    background-color: $modalBackground;
    .modal-dialog {
        max-width: 50% !important;
        .modal-content{
            box-shadow: 0px 0px 6px -2.9px $borderColor !important;
        }
    }

    .product-btn-section{
        border-radius: 0px 0px 6px 6px !important;
    }
    .add-edit-product {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .btn-save-up {
            background-color: $primaryColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-product-heading {
    @include modelHeader;
    border-radius: 6px 6px 0px 0px;
    i {
        color: $secondaryColor !important;
    }
    font-weight: 800 !important;
    // border-top-right-radius: 6px;
}
.btn-add-product-img {
    width: 100%;
    background-color: $lightBackgroundTheme;
    text-align: center;
    overflow: auto;

    &:hover {
        color: $whiteColor;
        background-color: $lightBackgroundTheme;
    }
}
.btn-add-main-img {
    width: 100%;
    font-size: 13px;
    background-color: $lightBackgroundTheme;
    color: $blackColor;
    text-align: center;

    &:hover {
        color: $whiteColor;
        background-color: $grayColor;
    }
}


/* SCROLL BAR CSS with Styling START */
/* width */
.products-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.products-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.products-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.products-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */

.thead-cell-products {
    min-width: 150px;
}
.dimension-cell {
    min-width: 200px;
}
.page-top-Filters {
    display: flex;
    // width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.default-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.product-list-div {
    padding: 20px 0px;
    max-height: 80vh;
    overflow-y: scroll;
}
.product-list-div::-webkit-scrollbar{
    display: none;
}
.place-order-div {
    padding: 10px;
    .summery-div{
        background-color: #f5f5f5;
        border-radius: 4px;
        // padding: 20px  40px;
        display: flex;
        justify-content: center;
        .summery-details {
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 0px 6px -2.9px $borderColor;
            border-radius: 6px;
            padding: 10px 20px;
            .total-count{
                display: flex;
                justify-content: flex-end;
                padding: 5px 15px;
            }
            .btn-div {
                display: flex;
                margin: 10px 0px 5px;
                justify-content: flex-end;
            }
        }
    }
}

.product-box-div {
    z-index: 1;
    box-shadow: 0px 0px 6px -2.9px $borderColor;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 180px;
    max-height: 180px;
    position: relative;
    .hover-section{
        display: none;
    }
    &:hover {
        box-shadow: 0px 0px 8px -1.9px $borderColor;
    }
}
.cart-box-div {
    z-index: 1;
    box-shadow: 0px 0px 6px -2.9px $borderColor;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 180px;
    max-height: 180px;
    &:hover {
        box-shadow: 0px 0px 8px -1.9px $borderColor;
    }
}
.product-filter-div {
    box-shadow: 0px 0px 6px -2.9px $borderColor;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 20px;
    height: 100%;
}
.product-details{
    display: flex;
    justify-content: left;
    position: relative;
    .product-display-img {
        cursor: pointer;
        height: 100%;
        .product-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .prod-name {
        cursor: pointer;
        font-size: 15px;
        font-weight: 700 !important;
        color: $primaryColor !important;
    }
    
    .icon-div-section {
        width: 100px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0px 0px 6px -2.9px $borderColor;
    }
    .select-section{
        width: auto !important;
        display: flex;
        // justify-content: flex-end;
        flex-direction: column;
        // align-items: ;
        // position: absolute;
        input{
            cursor: pointer;
        }
        .buy-btn-div{
            margin: 20px 0px 0px;
        }
        
    }
    
    .wish-list-section{
        width: auto !important;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 5;
        right: 0;
        .icon-div-section{
            background: #eee;
        }
    }
    
    
    .price-div {
        margin-right: 10px;
        .price-text {
            font-weight: 700;
            color: $primaryColor;
        }
    }
    .sb-product-details-div{
        // display: flex;
        // justify-content: space-between;
        .product-details-section {
            padding: 5px 0px 10px;
            &.status-section{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            h4{
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
    .price-text-div {
        display: flex;
        padding: 10px 0px 5px;
        align-items: center;
        // justify-content: space-between;
    }
    

    // For cart page
    .cart-heading-div {
        display: flex;
        justify-content: space-between;
    }
    .cart-description-div {
        padding: 10px 0px;
    }
    // For cart page
    
}

.cart-list-row-data{
    td{
        vertical-align: center ;
    }
}
.add-quantity{
    display: flex;
    align-items: center;

    .table-product-img{
        width: 16%;
        img{
            width: 100%;
        }
    }
    .prod-name-td {
        padding: 0px 30px;
        .prod-name {
            cursor: pointer;
            font-size: 16px;
            font-weight: 700 !important;
            color: $primaryColor !important;
        }
    }
    .qty-count-btn{
        padding: 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        cursor: pointer;
        border: none;
        font-size: 20px;
        border-radius: 6px;
        border: 1px dashed $secondaryColor;
        background-color: #fff;
        // box-shadow: 0px 0px 8px -1.9px $borderColor;
    }
    .qty-count{
        padding: 3px 20px;
        // border: 1px dashed #36e281;
        border-radius: 6px;
        // box-shadow: 0px 0px 8px -1.9px $borderColor;
    }
}

.product-box-div:hover{
    .hover-section {
        padding: 0px 10px;
        position: absolute;
        display: flex;
        align-items: flex-end;
        bottom: 20px;
        // height: 100%;
        right: 30px;
        // background-color: red;
        width: auto;
        
    }
}

.hover-badge {
    padding: 3px 20px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 5px 0px;
    cursor: pointer;
    // &.view-btn {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: #bbf8e4e8;
    //     color: #04a96d;
    //     border: 1px solid #bbf8e4e8;
    // }
    &.edit-btn {
        background-color: #ffeec091;
        color: #ffb900;
        border: 1px solid #ffeec091;
    }
    &.remove-btn {
        background-color: #f8d7da;
        color: #58151c;
        border: 1px solid #f1aeb5;
    }
}

.time-range-div {
    padding: 10px 20px;
    .display-range {
        padding: 10px 0px;
        text-align: center;
    }
    .category-filter {
        padding: 10px 0px;
        cursor: pointer;
        &.select-caregory {
            color: $primaryColor
        }
    }
}

// drag and drop images
.drop-area {
    padding: 20px;
    border-radius: 6px;
    border: 1px dashed $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    h6 {
        color: $primaryColor;
    }
    img {
        width: 10%;
    }
    .complaint-img {
        width: 20% !important;
    }
}
.drop-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    img {
        width: 10%;
    }
}
// drag and drop images



.add-edit-packaging{
    padding: 20px 20px;
}