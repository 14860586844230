@import "variables";
@import "mixins";

.passbook-first-div{
    width: 70%;
}
.passbook-second-div{
//    display: flex;
   width: 100%;
}
.passbook-third-div{
    width: 100%;
}
.passbook-main-container{
    // display: flex;
    position: relative;
}
.card-price b{
    display: flex;
    justify-content: space-around;
}
.card-price-graph img{
    width: 50px;
    height: 50px;
}
.passbook-card-body{
    padding: 15px;
    min-height: 85px;
    border-radius: 6px;
    box-shadow: $card_box_shadow;
    display: flex;
    align-items: center;
    width: 100%;
    .passbook-card-row, .card-data{
        display: flex;
    align-items: center;
    width: 100%;
    }
}
.passbook-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.card-passbook{
    width: 100%;
    border-radius: 0px !important;
    border: none;
    padding: 0px 20px;
    background-color: transparent;
}
.card-data{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.passbook-line-chart-container{
    padding: 20px;
}
.passbook-line-chart{
    height: auto;
    padding: 20px;
    border-radius: 6px;
    box-shadow: $card_box_shadow;
}
.card-details{
    padding: 5px 20px;
}
.card-details h6{
    padding-bottom: 10px;
}
.pan-qr img{
    width: 40px;
    height: 40px;
}
.pan-card-details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
}
.user-card-details{
    border-radius: 6px;
    box-shadow: $card_box_shadow;
    // width: 270px;
    height: auto;
    padding: 20px 10px;
    background-color: $activUserBackground;
}
.pancard-name-number .pan-name{
    color: grey;
    text-shadow: white 1px 1px 1px;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 2px;
}
.pan-card-details .pan-number{
    color: grey;
    text-shadow: white 1px 1px 1px;
    font-size: 14px;
    padding-left: 10px;
    letter-spacing: 1px;
}
.bank-acc-number span{
    display: grid;
    grid-template-columns: 90px 20px 150px;
    color: grey;
    text-shadow: white 1px 1px 1px;
    font-size: 13px;
    width: 100%;
    justify-content: flex-start;
}
.pan-card-image{
    height: 40px;
    width: 40px;
}
.passbook-sec-div-header{
    padding-bottom: 10px;
    font-size: 16px;
    color: $secondaryColor;
}