@import "variables";
@import "mixins";

.purchase-order-part {
    .po-table {
        .purchase-order-table {
            tbody {
                tr {
                    td {                        
                        .po {
                            text-transform: capitalize;                           

                            &.pending {
                                @include pendingStatus;
                            }

                            &.accepted {  
                                @include acceptedStatus;
                            }
                        }
                    }
                }
            }
        }
    }
}