@import "variables";
@import "mixins";

.leaves-table {
    @include tableProperty;
}

.leaves-scroll {
    height: calc(100vh - 210px);
    margin: 10px;
}

.leaves-thead {
    @include tableHeadProperty;
}

.leaves-table .btn-clear-select {
    @include buttonProperty($nav_back_Graycolor, 30px, 35px, 0, 16px, $whiteColor);
    margin-right: 10px;
}

#select-user-leaves {
    position: relative;
    margin-left: 400px;
}

.leaves-list-names {
    vertical-align: middle;
    align-items: center;
    .table-data-leaves-imgages {
        @include userImgProperty(25px);
        margin-right: 10px;
    }
    .name {
        text-transform: uppercase;
        font-weight: $fwt600;
    }
}

.leaves-list-row-data {
    .leave-status {
        color: $expirStatusColor;
        font-size: $fz13;
        &.approved{
            color:$successColor
        }
        &.rejected {
            color: $danderColor;
        }
        &.requested{
            color: $pendStatusColor;
        }
    }
    .leave-update-status {
        background-color: transparent;
        color: $primaryColor;
        vertical-align: middle;
        font-size: $fz12;
        padding: 0px;
        &.hide{
            visibility : hidden;
        }
    &:focus {
            box-shadow: none;
        }
    .bi-pencil-fill {
            margin-right: 5px;
        }
    }
} 

/* LEAVE UPDATE MODEL START */

.model-update-leavestatus-body {
    padding: 5px 10px;
}

.update-leavestatus-heading {
    @include modelHeader
}

.select-leave-update-status {
    border-radius: 5px;
    font-size: $fz13;
    background-color: $inputFieldback_color;
}

.update-leave-status .btn,
.update-leave-status .form-control {
    box-shadow: none;
}

.update-leave-status {
    .select-status {
        justify-content: space-between;
        align-items: center;
        margin: 0px 10px;
    }
} 

// .btns-save-cancel {
//     @include modelFooter;
// }


/* LEAVE UPDATE MODEL END */

/* SCROLL BAR CSS with Styling START */
/* width */
.leaves-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.leaves-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.leaves-scroll::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 0px;
}

/* Handle on hover */
.leaves-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */