@import "variables";
@import "mixins";

.add-user-details-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    // align-items: center;
    // border: 2px solid #e0e5eb;
    border-radius: 10px;
    // margin: 12% auto;
    margin: auto;
    height: 100vh;
}

.add-user-details-menu-numbers {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    cursor: pointer;
}

.add-user-details-menu-number-one {
    width: 40px;
    height: 40px;
    border: 1px solid #e0e5eb;
    border-radius: 50%;
    margin-right: 15px;
    color: #fff;
}

.add-user-details-menu-number-one span {
    position: absolute;
    top: 31px;
    left: 32px;
}

.add-user-details-menu-name {
    color: $primaryText_Color;
    font-size: 14px;
    font-weight: 400;
}

.add-user-details-first-div {
    display: flex;
    flex-direction: column;
    width: 15%;
    box-shadow: 2px 2px 2px var(--primary-palelight) !important;
    // padding: 20px 0px;
}

.add-user-details-second-div {
    width: 85%;
    height: 100%;
    overflow: auto;
    padding: 0 40px;
}

.add-user-details-second-div-header {
    padding: 32px 0;
    display: flex;
    align-items: center;

    .form-header-text {
        background: $header_Gradient;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: $fz20;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.mi-subform-header {
    padding-bottom: 2px;
    // display: flex;
    align-items: center;

    .form-header-sub {
        position: relative;
        margin-bottom: 10px;

        h6 {
            // text-align: center;
            // text-transform: uppercase;
            // padding-bottom: 5px;
        }

        h6:before {
            width: 28px;
            height: 5px;
            display: block;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 3%;
            // margin-left: -14px;
            background-color: $secondaryColor;
        }

        h6:after {
            width: 100px;
            height: 1px;
            display: block;
            content: "";
            position: relative;
            margin-top: 10px;
            left: 50px;
            margin-left: -50px;
            background-color: $secondaryColor;
        }
    }
}

.amc-single-image {
    padding: 0px 30px;
}

.add-user-details-next-icon {
    border: 1px solid;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 80px;
    bottom: 10px;
}

.amc-arrow-button {
    border: 1px solid;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 600px;
    // bottom: 10px;

    i {
        position: absolute;
        top: 9px;
        left: 13px;
        font-size: 20px;
    }

}

.leave-page-next-btn {
    top: 410px;
}

.add-user-details-next-icon i {
    position: absolute;
    top: 9px;
    left: 13px;
    font-size: 20px;
}

.add-user-details-menu-numbers.active .add-user-details-menu-number-one {
    background-color: $compStatusColor;
}

.add-user-details-menu-numbers.inactive .add-user-details-menu-number-one {
    background-color: $secondaryColor;
}

.upload-attachments-div label {
    border: none;
    padding: 7px;
    background-color: $secondaryColor;
    color: $whiteColor;
    border-radius: 8px;
}

.upload-attachments-div i {
    padding: 3px;
}

.amc-second-container-header {
    padding-bottom: 10px;
}

.amc-second-container {
    display: flex;

}

.amc-second-container-labels {
    padding-bottom: 25px;

    span {
        display: grid;
        grid-template-columns: 150px 20px 250px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .amc-arrow-button {
        left: 400px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .amc-second-container {
        display: block;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1900px) {
    .add-user-details-first-div {
        width: 20%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .add-user-details-first-div {
        width: 16%;
    }

    .add-user-details-second-div {
        width: 84% !important;
        padding: 0 20px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
    .add-user-details-second-div {
        width: 85%;
    }
}

.machines-next-arrow-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;

    span {
        font-size: 40px;
        cursor: pointer;
    }
}

.salesbee-logo-machines-add-page {
    // height: 100%;
    width: 100%;
    padding: 0px 30px;
}

.machine-details-cards {
    .card {
        border: none;
    }

    .accordion-button:focus {
        box-shadow: none;
    }
}

.machines-logo-div {
    background-color: #fff;
    width: 100%;
    height: 50px;
}

.add-page-save-cancel {
    padding: 15px 0px;
    display: flex;
    justify-content: flex-end;
}

.btn-add-machine-img {
    width: 100%;
    text-align: center;
}

.amc-add-icon {
    .amc-plus-icon {
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        margin: 10px 5px;
        padding: 4px 8px;
        border-radius: 5px;
        color: #fff;
        background-color: $primaryColor;
    }
}

.mi-addform-col-panel {
    // background-color: $primaryColor;
    box-shadow: 2px 2px 2px var(--primary-palelight) !important;
    padding: 20px;
}

.add-form-logo {
    // height: 10%;
    padding: 10px;

    .add-form-logo-link {
        height: 100%;
        display: block;

        .add-form-logo-img {
            width: 100%;
            height: 100%;
            position: relative
        }
    }
}

.mi-tab-nav-item {
    background-color: transparent !important;
    margin-bottom: 15px;

    .mi-tab-nav-item-link {
        display: flex;
        align-items: center;
        justify-self: flex-start;
        font-size: 14px;
        color: $primaryColor;
        font-weight: 700;
        padding: 0;
        border-bottom-left-radius: 22px;
        border-top-left-radius: 22px;
        background-image: linear-gradient(to right, rgba(66, 138, 121, 0.1) 30%, transparent 100%);

        .mi-tab-number-item {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .mi-tab-item-text {
            padding: 0 5px 0 20px;
            color: $primaryText_Color;
        }

        &.active {
            background-color: transparent !important;
            background-image: linear-gradient(to right, rgba(183, 169, 248, 0.598) 30%, transparent 100%) !important;
            color: $primaryColor;

            .mi-tab-number-item {
                background-color: $compStatusColor;
                border: 1px solid $compStatusColor;
                color: #fff;
            }
        }
    }

    &.empty-color {
        background-color: transparent !important;
        color: $primaryColor;

        .mi-tab-number-item {
            background-color: transparent !important;
            border: 1px solid $primaryColor !important;
            color: $primaryColor !important;
        }
    }

    &.filled-color {
        background-color: transparent !important;
        color: $primaryColor;

        .mi-tab-number-item {
            background-color: $primaryColor !important;
            border: 1px solid $primaryColor !important;
            color: #fff;
        }
    }
}


.preview-data-value {
    display: flex;
    align-items: flex-start;
    padding-bottom: 10px;
}

hr.dashed {
    border-top: 3px dashed $secondaryColor;
}

// Add for new css
.add-form-template-div {
    padding: 30px 10% 30px 3%;
    box-shadow:
        inset 0 3px 1px -1.9px #a894ff, inset 0 -3px 0px -1.9px #a894ff;
}

.add-form-save-cancel {
    padding: 15px 10% 10px 15px;
    border-top: 1px solid $borderColor;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 0px 6px 6px;
}

// Add for new css

// custom field
.fullname-form-control {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .sublabel-container {
        padding-right: 10px;
        width: 100%;
    }

    .drop-area {
        width: 100%;
    }

    .country-code {
        padding-right: 10px;
        width: 30%;
    }

    .meeting-date-picker-with-icon {
        width: 100%;
    }

    .react-select-list__control,
    .select-add-meeting-model.meeting-date {
        height: 30px !important;

    }

    .datepicker-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px !important;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        pointer-events: none;
        color: #ccc;
        /* Align it with your input field's text */
    }
}

// custom field