@import "variables";

.internet-issue-page{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    text-align: center;
    .internet-slow-img{
        width: 250px;
        height: 250px;
        text-align: center;
    }
    .internet-issue-head{
        color: blue;
    }
    .internet-issue-title{
        text-align: center;
    }
}