@import "variables";
@import "mixins";


.meetingpurpose-table {
    @include tableProperty;
}

.meetingpurpose-scroll {
    height: calc(100vh - 200px);
    margin : 10px;
}

.meeting-purpose-thead {
    @include tableHeadProperty;
}

.btn-add-purpose {
    @include buttonProperty($primaryColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $primaryColor
    }
    &:focus {
        box-shadow: none;
    }
    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}

.meeting-purpose-row-data .meeting-purpose-edit-link {
    color: $primaryColor;
    font-size: $fz14;
}


/* ADD & EDIT CLIENT MODEL START */

.add-meet-purpose-model .modal-content {
    width: 80% !important;
    margin-left: 50px;
}

.add-edit-meetpurpose {
    padding: 10px 20px;
}

.add-meet-purpose-heading {
    @include modelHeader;
}

.add-meetpurpose-input{
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-meet-purpose-model .add-edit-meetpurpose .btns-save-cancel {
    display: inline-flex;
    margin-bottom: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.add-edit-meetpurpose .btn-save-up {
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 5px;
    padding: 5px 15px;
    height: 30px;
    p {
        font-size: $fz15;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }
    &:hover {
        color: $whiteColor;
    }
}

.add-edit-meetpurpose .btn-cancel {
    background-color: $grayColor;
    color: $whiteColor;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
    height: 30px;
    p {
        font-size: $fz15;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }
    &:hover {
        color: $whiteColor;
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.meetingpurpose-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.meetingpurpose-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.meetingpurpose-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.meetingpurpose-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */

