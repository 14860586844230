@import "variables";
@import "mixins";

.whatsapp-table-container.modal-open {
    width: 60%;
    overflow: auto;
}

.whatsapp-module-main-div {
    display: flex;
}

.whatsapp-message-modal {
    width: 40%;
    border: 1px solid;
    border-top: none;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
}

.whatsapp-username {
    display: flex;
    justify-content: space-between;
}

.whatsapp-user-mobile-num {
    display: flex;
}

.whatsapp-username{
    &i {
        padding: 3px;
    }
} 

.whatsapp-user-chats {
    // margin: 10px 20px;
    display: flex;
    width: 100%;
    color: $lightGrayishCyan;
}

.whatsapp-user-chats-left {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 10px 20px;
    color: $lightGrayishCyan;
    width: 100%;
}

.whatsapp-user-chats p {
    box-shadow: $card_box_shadow;
    padding: 5px;
    max-width: 85%;
    border-radius: 4px;
    background-color: $lightGrayishCyan !important;
    color: $primaryColor !important;
}

.whatsapp-friend-chats-right {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    width: 100%;
    color: $lightGrayishCyan;
}

.whatsapp-friend-chats p {
    padding: 5px;
    box-shadow: $card_box_shadow;
    // max-width: 85%;
    width: auto;
    border-radius: 4px;
    background-color: $lightGrayishCyan !important;
    color: $secondaryColor !important;
}