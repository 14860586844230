@import "variables";
@import "mixins";

.po-details-part {
    padding: 1rem;
    min-height: 85vh;
    max-height: 90vh;
    overflow-x: hidden;

    .po-details-card {
        margin: 1rem 2rem;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: $card_box_shadow;

        .br-gray {
            border-right: 1.5px solid $iconBackground;
        }

        .bl-gray {
            border-left: 1.5px solid $iconBackground;
        }
    }

    .po-details-table-part {
        margin: 0.5rem;

        .css-41abqd-MuiTableContainer-root,
        .MuiTableContainer-root {
            min-height: 30vh !important;
            max-height: 30vh !important;
            overflow-x: hidden;
        }



        .po-details-table {
            tbody {
                tr {
                    td {
                        .po {
                            text-transform: capitalize;

                            &.pending {
                                @include pendingStatus;
                            }

                            &.accepted {
                                @include acceptedStatus;
                            }

                            &.cancelled {
                                @include cancelledStatus;
                            }
                        }
                    }
                }
            }
        }
    }
}