@import "variables";
@import "mixins";

.invoicestatus-page-table-part{
    .invoice-status-table{
        tbody{
            tr{
                td{
                   .invoice{
                        text-transform: capitalize;
                        margin: 0.25rem;                       
                        &.depat-status, &.finan-status{                           
                            &.approved{
                                @include acceptedStatus;
                            }
                            &.pending{                               
                                @include pendingStatus;
                            }
                        }
                        
                   }
                   .attachment{
                    cursor: pointer;
                    font-size: 1.2rem !important;
                    color: $primaryColor;
                   }
                }
            }
        }
    }
}