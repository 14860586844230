@import "variables";

.subscrption-box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    margin: 10px;

    .subscrption-image-data {
        text-align: center;
        .salesbee-logo {
                width: 60%;
                margin-bottom: 20px;
            }
        
            .subscription-expiry-model {
                text-align: center;
                background-color: $whiteColor;
                padding: 20px;
                box-shadow: $card_BoxShadow;
        
                .expiry-heading {
                    color: $OrangeColor;
                    font-size: $fz20 !important;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $grayColor;
                }
        
                p {
                    font-size: $fz15;
                    padding-top: 10px;
                }
            }
    }

    
}